<template>
  <v-card width="100%" class="my-2" flat outlined>
    <v-card-title class="d-flex justify-space-between align-center">
      <div class="d-flex align-center" style="font-size: 1.15rem;">
        <v-chip class="mr-2" color="primary lighten-1" label small>
          {{ data.service.toUpperCase() }}
        </v-chip>
        {{ data.title }}
      </div>
    </v-card-title>
    <v-card-text style="width: 100%">
      <perfect-scrollbar :options="{ suppressScrollX: true }">
        <div class="mb-2 d-flex justify-space-between align-cetner">
          <div>
            <v-chip
              v-for="category in data.categories"
              :key="category.name"
              :color="category.color"
              class="mx-1"
              outlined
              small
            >
              {{ category.name }}
            </v-chip>
          </div>
          <span class="text-body-2 mx-1">{{ releaseDate.format("LL") }}</span>
        </div>
        <div class="ck-content mt-8" v-html="data.content"></div>
      </perfect-scrollbar>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "Post",
  components: { PerfectScrollbar },
  props: {
    data: Object,
  },
  computed: {
    releaseDate() {
      return moment(this.data.releaseDate);
    },
  },
  created() {
    moment.locale(
      this.$i18n.locale === "zh-Hant" ? "zh-tw" : this.$i18n.locale
    );
  },
};
</script>

<style lang="scss">
@import "@/assets/ckeditor5.scss";

.ps {
  height: 100%;
  width: 100%;
}
</style>
