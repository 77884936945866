<template>
  <v-app style="height: 100vh">
    <v-app-bar app flat dense dark color="primary">
      <v-text-field
        v-model="search"
        :placeholder="$tc('search')"
        append-icon="mdi-magnify"
        clearable
        dense
        solo-inverted
        flat
        hide-details
        @change="fetchData"
        @click:append="fetchData"
      ></v-text-field>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            text
            :color="hasFilter ? 'success lighten-3' : 'white'"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-if="hasFilter"> mdi-filter-check-outline </v-icon>
            <v-icon v-else>mdi-filter-outline</v-icon>
          </v-btn>
        </template>
        <v-card max-height="100%">
          <v-card-text>
            <h2 class="subtitle-2 mb-2">{{ $tc("service") }}</h2>

            <v-chip-group v-model="selectedService" column>
              <v-chip
                v-for="item in services"
                :key="item.name"
                class="mr-2"
                color="primary lighten-1"
                filter
                label
                small
              >
                {{ item.name.toUpperCase() }}
              </v-chip>
            </v-chip-group>
          </v-card-text>

          <v-card-text>
            <h2 class="subtitle-2 mb-2">{{ $tc("tags") }}</h2>

            <v-chip-group v-model="selectedTags" column>
              <v-chip
                v-for="item in tags"
                :key="item.name"
                class="mr-2"
                :color="item.color"
                filter
                outlined
                small
              >
                {{ item.name }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main style="height: 100%; overflow: hidden;">
      <perfect-scrollbar>
        <v-card
          class="mx-auto px-2"
          width="100%"
          max-width="900"
          tile
          flat
          :loading="fetching"
        >
          <template v-for="changelog in changelogs">
            <post :data="changelog" :key="changelog._id"></post>
          </template>
          <v-btn
            v-if="loaded < total"
            width="100%"
            plain
            @click="loaded += batch"
          >
            {{ $tc("seeMore") }}
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </v-card>
      </perfect-scrollbar>
    </v-main>
  </v-app>
</template>

<script>
import { version } from "/package.json";
import Post from "@/views/Post.vue";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'


export default {
  name: "App",
  components: { Post, PerfectScrollbar },
  data: () => ({
    version,
    logo: `${process.env.VUE_APP_RESOURCE_CDN_URL}/logo/syncobox-logo.png`,
    drawer: true,
    selectedService: undefined,
    selectedTags: undefined,
    services: [
      {
        id: "form",
        icon: "mdi-alert-circle-outline",
        name: "Form",
      },
      {
        id: "bim",
        icon: "mdi-alert-circle-outline",
        name: "BIM",
      },
      {
        id: "panorama",
        icon: "mdi-alert-circle-outline",
        name: "Panorama",
      },
      {
        id: "document",
        icon: "mdi-alert-circle-outline",
        name: "Document",
      },
      {
        id: "report",
        icon: "mdi-alert-circle-outline",
        name: "Report",
      },
      {
        id: "common",
        icon: "mdi-alert-circle-outline",
        name: "Common",
      },
    ],
    tags: [
      {
        id: "releaseNotes",
        name: "Release Notes",
        color: "info",
      },
      {
        id: "newFeature",
        name: "New Feature",
        color: "warning",
      },
      {
        id: "improvement",
        name: "Imporvement",
        color: "accent",
      },
      {
        id: "fix",
        name: "Fix",
        color: "error",
      },
      {
        id: "beta",
        name: "Beta",
        color: "secondary",
      },
      {
        id: "announcement",
        name: "Announcement",
        color: "success",
      },
    ],
    changelogs: [],
    detail: null,
    search: "",
    loaded: 5,
    batch: 5,
    total: 1,
    fetching: false,
  }),
  computed: {
    hasFilter() {
      return (
        typeof this.selectedService === "number" ||
        typeof this.selectedTags === "number"
      );
    },
  },

  created() {
    this.drawer = !this.$vuetify.breakpoint.smAndDown;
    this.fetchData();
  },
  mounted() {
    console.log(this.$vuetify.breakpoint);
  },
  methods: {
    fetchData() {
      this.fetching = true;
      fetch(`${process.env.VUE_APP_CHANGELOG_URL}?${this.getKendoQuery()}`)
        .then((res) => res.json())
        .then((json) => {
          console.log(json);

          // create tags
          json.data.forEach((item) => {
            item.categories = this.createTags(item.categories || item.select);
          });
          this.changelogs = json.data;
          this.total = json.total;
          this.fetching = false;
        });
    },
    createTags(categories) {
      return categories.map((e) => {
        let tag = this.tags.find((tag) => tag.id === e);
        return { name: tag.name, color: tag.color };
      });
    },
    onReadmoreClick(item) {
      this.detail = item;
    },
    onGoBack() {
      this.detail = null;
    },
    getKendoQuery() {
      const filters = [];

      if (typeof this.selectedService === "number") {
        filters.push({
          field: "service",
          operator: "contains",
          value: this.services[this.selectedService].id,
        });
      }

      if (typeof this.selectedTags === "number") {
        filters.push({
          field: "categories",
          operator: "contains",
          value: this.tags[this.selectedTags].id,
        });
      }

      if (this.search) {
        filters.push({
          field: "title",
          operator: "contains",
          value: this.search,
        });
      }

      const req = toDataSourceRequestString({
        skip: 0,
        take: this.loaded,
        sort: [
          {
            field: "releaseDate",
            dir: "desc",
          },
        ],
        filter: {
          filters,
          logic: "and",
        },
      });

      console.log(req);
      return req;
    },
    test(e) {
      console.log(e);
    },
  },
  watch: {
    selectedService: {
      immediate: false,
      deep: true,
      handler() {
        this.fetchData();
      },
    },
    selectedTags: {
      immediate: false,
      deep: true,
      handler() {
        this.fetchData();
      },
    },
    loaded: {
      immediate: false,
      deep: false,
      handler() {
        this.fetchData();
      },
    },
  },
};
</script>

<style lang="scss">
.ps {
  position: relative;
  height: 100%;
  overflow: auto;
}
</style>
